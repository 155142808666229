<template>
  <div class="register">
    <!-- register_annual_subheader -->
    <section id="sub_header_box" class="annual">
      <div class="w1300">

        <div class="sub_header_description txt-white page_header_space">
          <h3 class="sub_header_description_title">台灣居家醫療醫學會2020年學術研討會暨第二屆年會</h3>
          <p>今年主題著重於居家安寧病人照護實務經驗。</p>
          <p>藉由實務經驗分享，互相學習交流，讓居家醫療更能符合患者需求。</p>
        </div>

        <img class="heart_bg" src="@/statics/img/about/bg_heart.png" alt="">

      </div>
    </section>

    <PageSubHeader :pageSubHeader="pageSubHeader" />

    <section id="annual_member">
      <div class="w1300 annual_container">

        <div class="main_title_box">
          <h3 class="main_title txt-bold">會員報名</h3>
          <div class="img_box">
            <img src="@/statics/img/index/icon_title2.svg" alt="">
          </div>
        </div>

        <div class="annual_container_box">

          <div class="success_info txt-center">
            <p>
              王小明 您好，<br><br>
              您的報名編號為 <span class="txt-bold">00032，</span><br><br>
              <span class="txt-bold">恭喜您成功報名本屆年會!</span><br><br>
              您可以至課程活動紀錄查詢報名紀錄。
            </p>

            <a href="register_record.php" class="btn">報名查詢</a>
          </div>

        </div>

      </div>
    </section>
  </div>
</template>

<script>
import PageSubHeader from '@/components/PageSubHeader.vue';
import { RegisterAnnualSubHeader } from '@/lib/const';

export default {
  name: 'RegisterAnnualSuccess',
  data() {
    return {
      ...RegisterAnnualSubHeader,
    };
  },
  components: {
    PageSubHeader,
  },
};
</script>
